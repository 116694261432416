<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <card>
          <template v-slot:searchHeaderTitle>
            <h4 class="card-title">{{ $t('tpm_report.training_course_wise_attendance_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:searchBody>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                  plain
                                  v-model="formData.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                          <ValidationProvider name="Organization"  vid="org_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Start Date" vid="training_start_date">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_start_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_iabm.training_start_date') }}
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.training_start_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training End Date" vid="training_end_date">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_end_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('elearning_iabm.training_end_date') }}
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="formData.training_end_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-overlay>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <!-- <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button> -->
                          <b-button size="sm" type="submit" variant="primary" class="mr-2">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                          </b-button>
                        &nbsp;
                      </div>
                      </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </card>
        <b-row>
            <b-col md="12" v-if="showData">
              <body-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.training_course_wise_attendance_report') }}</h4>
                </template>
                <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                                <b-col v-for="(info, index) in dateList" :key="index">
                                    <b-form-checkbox
                                    :id="'checkbox-' + info.text"
                                    v-model="info.checked"
                                    :name="'checkbox-' + info.text"
                                    value=2
                                    unchecked-value=1
                                    @change="checkCount(info)"
                                    >
                                    {{info.text | dateFormat}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                                <b-col>
                                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
                                        {{ $t('tpm_report.training_course_wise_attendance_report') }}
                                    </list-report-head>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                  <div class="text-center">
                                    <b-row>
                                      <b-col>
                                        <table style="width:100%;color:black;">
                                          <tr v-if="formData.circular_memo_no">
                                            <th align="right" style="width:35%">{{ $t('elearning_iabm.circular_memo_no') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ formData.circular_memo_no }}</td>
                                          </tr>
                                          <tr v-if="formData.org_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.organization') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.org_bn : formData.org }}</td>
                                          </tr>
                                          <tr v-if="formData.office_id">
                                            <th align="right" style="width:35%">{{ $t('globalTrans.office') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.ofc_name_bn : formData.ofc_name }}</td>
                                          </tr>
                                          <tr v-if="formData.training_category_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.training_category') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.training_category_bn : formData.training_category }}</td>
                                          </tr>
                                          <tr v-if="formData.training_start_date">
                                            <th align="right" style="width:35%">{{ $t('elearning_iabm.training_start_date') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ formData.training_start_date | dateFormat }}</td>
                                          </tr>
                                        </table>
                                      </b-col>
                                      <b-col>
                                        <table style="width:100%;color:black;">
                                          <tr v-if="formData.fiscal_year_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.fiscal_year') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                                          </tr>
                                          <tr v-if="formData.office_type_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.office_type') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.ofc_type_name_bn : formData.ofc_type_name }}</td>
                                          </tr>
                                          <tr v-if="formData.training_type_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.training_type') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.training_type_bn : formData.training_type }}</td>
                                          </tr>
                                          <tr v-if="formData.training_title_id">
                                            <th align="right" style="width:35%">{{ $t('elearning_config.training_title') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ ($i18n.locale==='bn') ? formData.training_title_bn : formData.training_title }}</td>
                                          </tr>
                                            <tr v-if="formData.training_end_date">
                                            <th align="right" style="width:35%">{{ $t('elearning_iabm.training_end_date') }}</th>
                                            <th align="center" style="width:5%">:</th>
                                            <td align="left" style="width:60%">{{ formData.training_end_date | dateFormat }}</td>
                                          </tr>
                                        </table>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </b-row>
                            <b-row>
                                <b-col lg="12" sm="12" v-if="details.length > 0">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                                        <b-row>
                                        </b-row>
                                        <b-row>
                                        <div class="col-md-12">
                                            <!-- <fieldset class="p-2 w-100"> -->
                                                <h4 class="card-title">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</h4>
                                            <!-- <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend> -->
                                                <!-- <b-table-simple hover bordered responsive> -->
                                                <b-table-simple hover bordered responsive  v-if="dateList.filter(item1 => item1.checked === '2').length>0">
                                                <b-thead class="thead">
                                                    <b-tr>
                                                      <b-th rowspan="3" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                                      <b-th rowspan="3">{{$t('elearning_tim.trainee')}}</b-th>
                                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                                                      <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('elearning_tim.posting_office')}}</b-th>
                                                      <b-th rowspan="3">{{$t('globalTrans.mobile')}}</b-th>
                                                      <b-th style="width:10%" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                                                    </b-tr>
                                                    <b-tr>
                                                        <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                                            <b-th class="text-center" colspan="2">
                                                                {{ field.text | dateFormat }}
                                                            </b-th>
                                                        </slot>
                                                    </b-tr>
                                                    <b-tr>
                                                      <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                                        <b-th class="text-center" :id="'morning-' + field.value">
                                                          {{$t('globalTrans.morning')}}
                                                        </b-th>
                                                        <b-th class="text-center" :id="'day-' + field.value">
                                                          {{$t('globalTrans.day')}}
                                                        </b-th>
                                                      </slot>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tr v-for="(train,index) in details" :key="index">
                                                    <b-td class="text-center">{{$n(index+1)}}</b-td>
                                                    <b-td>
                                                    {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                      {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                      {{ (($i18n.locale==='bn') ? train.office_name_bn : train.office_name) + ', ' + (($i18n.locale==='bn') ? train.org_name_bn : train.org_name) }}
                                                    </b-td>
                                                    <b-td>{{ train.mobile }}
                                                    <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                                                        {{ detailsErrors['details.'+index+'.mobile'] }}
                                                    </small>
                                                    </b-td>
                                                    <slot v-for="(field, index1) in dateList.filter(item1 => item1.checked === '2')">
                                                      <b-td class="text-center">
                                                        <slot v-if="details[index].dateList[index1].morning">
                                                            {{$t('elearning_tim.present')}}
                                                        </slot>
                                                        <slot v-else>
                                                            {{$t('elearning_tim.absent')}}
                                                        </slot>
                                                      </b-td>
                                                      <b-td class="text-center">
                                                        <slot v-if="details[index].dateList[index1].day">
                                                            {{$t('elearning_tim.present')}}
                                                        </slot>
                                                        <slot v-else>
                                                            {{$t('elearning_tim.absent')}}
                                                        </slot>
                                                      </b-td>
                                                    </slot>
                                                </b-tr>
                                                <template v-if="details.length === 0">
                                                    <tr>
                                                    <th :colspan="dateList.length" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                                    </tr>
                                                </template>
                                                </b-table-simple>
                                            <!-- </fieldset> -->
                                        </div>
                                        </b-row>
                                    <!-- </b-overlay> -->
                                    </b-form>
                                </ValidationObserver>
                                </b-col>
                            </b-row>
                        </div>
                    </b-overlay>
                </template>
              </body-card>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

// import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, circularPublication, trainingModuleWiseTrainer, trainingApplicationAllList, circularWiseAttendanceList, circularPublicationList } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf_attendence_details'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    // ValidationProvider,
    ListReportHead
  },
  created () {
    this.getCircularList()
    // this.getCircularMemoNo(this.$route.query.circularMemoNo)
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      showData: false,
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: 0,
        org: '',
        org_bn: '',
        office: '',
        office_bn: '',
        office_type: '',
        office_type_bn: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        training_category: '',
        training_category_bn: '',
        training_type: '',
        training_type_bn: '',
        training_title: '',
        training_title_bn: '',
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        org_id: 0,
        office_id: 0,
        office_type_id: 0,
        course_id: 0,
        course_module_id: 0,
        training_date: '',
        trainer_id: 0,
        registration_for: 2,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      officeTypeList: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      officeList: [],
      courseModuleList: [],
      courseList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      dateList: []
    }
  },
  computed: {
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.course_id': function (newValue) {
      this.courseModuleList = this.getCourseModuleList(newValue)
      this.getTrainerList(newValue)
    },
    'formData.trainer_id': function (newValue) {
      this.getAttedenceList()
    },
    'formData.training_date': function (newValue) {
      this.getAttedenceList()
    }
  },
  methods: {
    pdfExport () {
        const reportTitle = this.$t('tpm_report.training_course_wise_attendance_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData, reportTitle, this.formData, this, this.details, this.dateList.filter(item1 => item1.checked === '2'))
    },
    async searchData () {
        this.circularWiseAttendance()
    },
    getCustomDataSearch () {
        const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(organizationProfileList => organizationProfileList.value === parseInt(this.formData.org_id))
        if (typeof orgObj !== 'undefined') {
          this.formData.org = orgObj.text_en
          this.formData.org_bn = orgObj.text_bn
        } else {
          this.formData.org = ''
          this.formData.org_bn = ''
        }
        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.formData.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.formData.fiscal_year = fiscalYearObj.text_en
          this.formData.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.formData.fiscal_year = ''
          this.formData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.formData.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          this.formData.training_title = trainingTitleObj.text_en
          this.formData.training_title_bn = trainingTitleObj.text_bn
        } else {
          this.formData.training_title = ''
          this.formData.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.formData.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
          this.formData.training_type = trainingTypeObj.text_en
          this.formData.training_type_bn = trainingTypeObj.text_bn
        } else {
          this.formData.training_type = ''
          this.formData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.formData.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
          this.formData.training_category = trainingCategoryObj.text_en
          this.formData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          this.formData.training_category = ''
          this.formData.training_category_bn = ''
        }
        const ofcTypeObj = this.$store.state.CommonService.commonObj.officeTypeList.find(ofcType => ofcType.value === parseInt(this.formData.office_type_id))
        if (typeof ofcTypeObj !== 'undefined') {
          this.formData.ofc_type_name = ofcTypeObj.text_en
          this.formData.ofc_type_name_bn = ofcTypeObj.text_bn
        } else {
          this.formData.ofc_type_name = ''
          this.formData.ofc_type_name_bn = ''
        }
        const ofcObj = this.$store.state.CommonService.commonObj.officeList.find(ofcList => ofcList.value === parseInt(this.formData.office_id))
        if (typeof ofcObj !== 'undefined') {
          this.formData.ofc_name = ofcObj.text_en
          this.formData.ofc_name_bn = ofcObj.text_bn
        } else {
          this.formData.ofc_name = ''
          this.formData.ofc_name_bn = ''
        }
      },
    checkCount (item) {
        const total = this.dateList.filter(item1 => item1.checked === '2')
        if (total.length > 4) {
            this.$toast.error({
            title: 'Error',
                message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ চারFourটি তারিখ যোগ করা যাবে!' : 'Maximum Four dates can be added!'
            })
            item.checked = false
            item.checAll = false
        }
    },
    async getCircularList () {
      this.circularLoading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar')
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    getCourseList (circularMemoNo) {
      const courseList = this.$store.state.TrainingElearning.commonObj.courseList
      if (circularMemoNo) {
        return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
      }
      return courseList
    },
    getCourseModuleList (courseId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList
      if (courseId) {
        return courseModuleList.filter(item => item.course_id === courseId)
      }
      return courseModuleList
    },
    async getAttedenceList () {
       this.getCustomDataSearch()
      if (this.formData.circular_memo_no) {
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationAllList, this.formData)
        if (result.success) {
        //   var temp = []
          const datas = result.data.map(item => {
            var fertilizerAgent = []
            const desigObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            this.dateList.forEach((dateList, dateListIndex) => {
                const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
                const items = {}
                items.training_application_id = item.id
                if (typeof saveObj !== 'undefined') {
                    items.morning = saveObj.morning
                    items.day = saveObj.day
                    items.checkData = true
                    items.text = dateList.text
                } else {
                    items.morning = false
                    items.day = false
                    items.checkData = false
                    items.text = dateList.text
                }
                fertilizerAgent.push(items)
            })
            const newData = {
                dateList: fertilizerAgent
            }
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.CommonService.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
            // temp.push(fertilizerAgent)
            return Object.assign({}, item, desigData, newData, orgData, officeData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = []
          this.loading = false
        }
      }
    },
    checkData (item) {
      this.details.forEach((detail, detailIndex) => {
        detail.dateList.forEach((dateList, dateListIndex) => {
          if (dateList.text === item.text) {
            if (item.checkAll === '2') {
              dateList.checkData = true
            } else {
              dateList.checkData = false
            }
          }
        })
      })
    },
    async circularWiseAttendance () {
      this.showData = true
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularWiseAttendanceList, this.formData)
      if (result.success) {
        this.circularWiseAttendanceList = result.data
      } else {
        this.circularWiseAttendanceList = []
      }
      this.getAttedenceList()
      this.loading = false
    },
    async getTrainerList (courseId) {
    //   this.loading = true
      this.formData.trainer_id = 0
      this.trainerListData = []
      this.trainerLoading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingModuleWiseTrainer + '/' + courseId)
      if (!result.success) {
        this.trainerListData = []
        // this.loading = false
      } else {
        this.trainerListData = result.data
        // this.loading = false
      }
      if (result.data.length > 0) {
        this.formData.trainer_id = result.data[0].value
      }
      this.trainerLoading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
    //   this.loading = true
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'tpm_training_calendar'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      if (!result.success) {
        this.formData.fiscal_year_id = 0
        this.formData.org_id = 0
        this.formData.office_id = 0
        this.formData.office_type_id = 0
        this.formData.training_title_id = 0
        this.formData.training_type_id = 0
        this.formData.training_category_id = 0
        this.courseList = []
        this.trainingCalendar = []
        this.formData.training_start_date = ''
        this.formData.training_end_date = ''
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.trainingCalendar = result.training_calendar
        this.formData.fiscal_year_id = trData.fiscal_year_id
        this.formData.org_id = trData.org_id
        this.formData.office_id = trData.office_id
        this.formData.office_type_id = trData.office_type_id
        this.formData.training_title_id = trData.training_title_id
        this.formData.training_type_id = trData.training_type_id
        this.formData.training_category_id = trData.training_category_id
        this.formData.training_start_date = result.training_calendar.training_start_date
        this.formData.training_end_date = result.training_calendar.training_end_date
        this.courseList = this.getCourseList(getCircularMemoNo)
        const listData = result.aryRange.map(item => {
            // this.loading = false
            const updateData = {}
            updateData.checked = false
            updateData.checkAll = false
            updateData.text = item
            return Object.assign({}, updateData)
        })
        this.dateList = listData
        // this.circularWiseAttendance()
        this.load = false
      }
      this.load = false
    },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        // result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.getAttedenceList()
        this.$router.push('/training-e-learning-service/tim/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
