// Bill Payment api
export const timbillPaymentBaseUrl = '/report/tim/'
export const billPaymentReport = timbillPaymentBaseUrl + 'payment-report'
export const billPaymentReportPdf = timbillPaymentBaseUrl + 'payment-report-pdf'
//
export const dormitoryOtherChargeReport = '/report/dormitory-others-charge-report'
export const trainingCourseSummaryReport = '/report/training-course-summary-report'
export const trainingMaterialReport = '/report/training-material-report'
export const trainingCourseAttendenceReport = '/report/training-course-wise-attendence-report'
export const performanceReport = '/report/performance-report'
export const traineePerformanceReport = '/report/trainee-performance-report'
export const trainerPerformanceReport = '/report/trainer-performance-report'
export const trainingSummryReport = '/report/training-summary-report'
export const dormitoryOthersChargeReport = '/report/dormitory-others-charge-report'
export const attendanceBaseUrl = '/tim/attendances/'
export const attendanceStore = attendanceBaseUrl + 'store'
export const circularWiseAttendanceList = attendanceBaseUrl + 'circular-wise_attendance'
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'module-wise-trainer'
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const attendenceSheetBaseUrl = '/tpm/training-attendence/'
export const attendenceSheetList = attendenceSheetBaseUrl + 'list'
// Training Course Evaluation Report
export const manualCourseEvaluationBaseUrl = '/tim/manual-course-evaluation/'
export const manualCourseEvaluation = manualCourseEvaluationBaseUrl + 'manual-course-evaluation'
export const circularPublication = '/tpm/training-calendar/circular-publication'
export const circularpublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = circularpublicationBaseUrl + 'circular-publication-list'
export const otherMaterialReport = '/tim/bill-payment/honorarum-list-details'
// Venue Reservation Report
export const venueReservationReport = '/report/venue-reservation-report'
// Circular Publication Report
export const circularPublicationReportList = '/report/circular-publication-report'
export const orgByFiscalYear = '/report/circular-publication/org-by-fiscal-year'
export const circularMemoByOrgId = '/report/circular-publication/circular-memo-org-id'
// circular memo list
export const trainingPublicationBaseUrl = '/iabm/circular-publications/'
export const circularMemoList = trainingPublicationBaseUrl + 'circular-publication-list'
export const circularData = trainingPublicationBaseUrl + 'circular-publication-data'
// trainer trainee info Report apis
export const orgCircularList = trainingPublicationBaseUrl + 'org-circular-publication-list'
export const trainerTraineeInfoReportList = '/report/tim/trainer-trainee-info-report'
